/* 커서 */
.custom-cursor {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #5863ffba;
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 9999;
    transition: transform 2s linear;
  }

@media screen and (max-width: 768px) {
    .custom-cursor {
      display: none;
    }
  }


/* 폰트 */

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

html, body {
    font-family: 'Pretendard', sans-serif;
    color: #2E3239;
}

.cst-blue{
    color: #5864FF;
}

.cst-grey{
    color: #767d8a;
}

.cst-title-1{
    font-size: 3em;
}

.cst-title-2{
    font-size: 1.8em;
}

.cst-title-sub{
    font-size: 1.3em;
    font-weight: 700;
}

.cst-title-sub-2{
    font-size: 1.3em;
}

@media (max-width: 768px) {
    .cst-title-1 {
      font-size: 2.3rem;
    }

    .cst-title-sub{
        font-size: 1.1em;
    }
  
    .cst-title-sub-2 {
      font-size: 1.1rem;
    }
  }

.bold{
    font-weight: 800;
}

.gradient-text {
    background: linear-gradient(to right, #5864FF, #c9ccff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

.gradient-text-dark {
  background: linear-gradient(to right, #5864FF, #9398ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.typewriter-text {
margin-left: 20px;
}

/* 하이라이트 처리 */
.highlight-blue {
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.section-service-box:hover .highlight-blue {
  background-color: #dfe1ff;
}

.highlight-darkblue {
    background-color: transparent;
    transition: background-color 0.3s ease;
  }
  
  .section-service-box-blue:hover .highlight-darkblue {
    background-color: #222fdd;
  }

/* 배경 */
.cst-back-dark{
    background-color: #2E3239;
    color: #fff;
}

.cst-back-white{
    background-color: #fff;
}

.grey{
    background-color: #F8FCFF;
}

/* 컨테이너 */
/* 컨테이너 - 페이드인효과 */
.fade-in-section {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
.fade-in-section.visible {
  opacity: 1;
  transform: translateY(0);
}
/* 컨테이너 - 네브바 */
.navbar-nav .nav-link {
    cursor: pointer;
  }
  
.navbar-nav .dropdown{
    padding-right: 20px;
}
  
  .navbar-nav .dropdown-menu {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s, max-height 0.5s ease;
  }
  
  .navbar-nav .dropdown:hover .dropdown-menu {
    opacity: 1;
    max-height: max-content;
    display: block; /* 호버 시 메뉴를 보이도록 설정 */
}
.dropdown-item {
    padding-top: 10px; /* 위쪽 패딩 */
    padding-bottom: 10px; /* 아래쪽 패딩 */
  }

@media (max-width: 768px) { /* 모바일 화면 크기를 위한 미디어 쿼리 */
    .dropdown-item {
      padding-top: 10px; /* 위쪽 패딩 */
      padding-bottom: 10px; /* 아래쪽 패딩 */
    }
  }

/* 컨테이너 - 타이틀섹션 */
.section-title{
    padding-top: 60px;
    padding-bottom: 80px;
    background-image: url('../assets/section-title-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
/* 컨테이너 - 서비스섹션 */
.section-service{
    padding: 40px 0;
}

.section-service-box{
    background-color: #fff;
    color: #2E3239;
    text-align: left;
    border-radius: 8px;
    padding: 8px 16px;
}

.section-service-box div{
    height: 100px;
    width: 100px;
    border-radius: 16px;
}

.section-service-box img{
    width: 100%;       
    height: 100%;      
    object-fit: cover;
}
    

.section-service-box:hover {
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 1);
    transition: box-shadow 0.4s ease-in-out;
  }

.section-service-box-blue{
    background-color: #414eff;
    color: #fff;
    text-align: left;
    border-radius: 8px;
    padding: 8px 16px;
}

.section-service-box-blue div{
    height: 100px;
    width: 100px;
    border-radius: 16px;
}

.section-service-box-blue img{
    width: 100%;       
    height: 100%;      
    object-fit: cover;
}

.section-service-box-blue:hover {
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 1);
    transition: box-shadow 0.4s ease-in-out;
}

/* 컨테이너 - 타입섹션 */
.section-type-box{
    margin-bottom: 40px;
}

.section-type-img-box{
    height: 300px;
    background-color: #ecf0f3;
}

.section-type-text-box p{
    font-size: 0.95em;
    font-weight: 500;
    color: #4d525b;
}

.section-type-text-box button{
    border: none;
    font-size: 0.8em;
    background-color: transparent;
    color: #414eff;
    padding: 0;
    font-weight: 700;
}

/* 컨테이너 - 기술스택섹션 */

.section-stack-box{
    background-color: #fff;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.01);
    padding: 16px 0;
}

.section-stack-text-box {
    font-size: 0.7em;
    color: #b3b5d9;
    padding: 8px 12px;
    font-weight: 700;
    background-color: #EFF0FF;
    border-radius: 20px;
    margin-top: 10px;
    max-width: fit-content; 
    margin-left: auto;
    margin-right: auto;
}
.stack-box{
    height: 40px;
    margin: 20px 5px;
}

.stack-box img{
    object-fit: contain;
    max-width: 100%;
    max-height: 45px;
    height: auto;
}

/* 컨테이너 - 포트폴리오 섹션 */

.section-portfolio{
    padding: 40px 0 120px 0;
}

.portfolio-box{
    border: none;
    width: 100%;
    padding: 0;
    background-color: transparent;
}

.portfolio-thumbnail{
    width: 100%;
    height: 200px;
    background-color: #fff;
    margin-bottom: 12px;
    overflow: hidden;
    border-radius: 8px;
}

.portfolio-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

.portfolio-box h4{
    font-size: 1.1em;
    color: #2E3239;
}

.cst-box-tag{
    background-color: #EFF0FF;
    border-radius: 10px;
    color: #b3b5d9;
    font-size: 0.8em;
    padding: 2px 6px;
}

/* 컨테이너 - 문의 섹션 */

.section-contact{
    background-image: linear-gradient(#F8FCFF 50%, #2E3239 50%);
    padding-bottom: 80px;
}

.section-contact-box{
    background: linear-gradient(#414eff, #515cff);
    border-radius: 16px;
    color: #fff;
    padding: 60px 0;
}

.section-contact-box h1{
    font-size: 2em;
    font-weight: 600;
}

.section-contact-box p{
    font-size: 1.2em;
}

.section-contact-box button{
    border: none;
    background-color: #fff;
    color: #414eff;
    padding: 16px 28px;
    font-weight: 700;
    border-radius: 14px;
}

@media (max-width: 768px) {
    .section-contact-box h1{
        font-size: 1.5em;
        font-weight: 600;
    }
    
    .section-contact-box p{
        font-size: 1em;
    }

    .section-contact-box{
        background: linear-gradient(#414eff, #515cff);
        border-radius: 16px;
        color: #fff;
        padding: 30px 0;
    }
    
  }

/* 컨테이너 - 포트폴리오 페이지 */

.portfolioWeb-page{
    padding-top: 100px;
}

@media (max-width: 768px) {
    .portfolioWeb-page {
        padding-top: 50px;
    }
}

.project-image-container {
    width: 100%;
    height:auto;
    position: relative;
    overflow: hidden; 
  }

  .project-image-container:hover .project-image {
    transform: scale(1.1);
  }

  .project-image-container:hover .project-title{
    background-color: #414eff;
  }

  
  .project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .project-title {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
  }
  

  /* 컨테이너 - 포트폴리오 상세 페이지 */

  .project-details-container {
    text-align: center;
    margin: auto;
    padding-bottom: 80px;
  }

  
  .project-details-container h2{
      padding: 80px 0 10px 0;
      font-weight: 700;
    }
    @media (max-width: 768px) {
      .project-details-container h2 {
          padding-top: 40px;
      }
  }
    
  .project-details-container p{
    line-height: 1;
  }
  
  .project-details-container img {
    max-width: 100%;
    height: auto; 
  }

  .project-details-container .project-tags {
    text-align: center;
    padding-bottom: 40px;
    font-size: 0.9em;
  }

  .project-details-container .project-tags .project-tag {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    background-color: #dfe1ff;
    border-radius: 5px; 
  }


/* 컨테이너 - 상담신청 페이지 */

.contactwrite-box{
    padding: 64px 0;
}

.contactwrite-box button{
    padding: 14px;
    width: -webkit-fill-available;
    border-radius: 10px;
}

.contactwrite-example{
    margin: 24px 0;
    padding: 8px 2px 2px 2px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: #414eff;
    color: #fff;
}

.contactwrite-example-box{
    padding: 40px 24px;
    border-radius: 16px;
    background-color: #fff;
}

.contactwrite-example h2{
    font-size: 0.9em;
    font-weight: 700;
    color: #fff;
}

.contactwrite-example-box h3{
    font-size: 1em;
    font-weight: 700;
    color: #2E3239;
    text-align: left;
}

.contactwrite-example-box p{
    font-size: 0.9em;
    font-weight: 400;
    color: #767d8a;
    text-align: left;
    margin-bottom: 30px;
}


/* 컨테이너 - 푸터 */
.footer-box{
    padding-bottom: 80px;
}

.footer-box h5{
    font-size: 1em;
}

.footer-box p{
    font-size: 0.9em;
    color: #b3b5d9;
    margin-bottom: 8px;
}

.footer-logo img{
    width: auto;
    height: 40px;
}

.footer-text-box{
    margin-bottom: 20px;
}


/* 버튼 */
.cst-primary-btn{
    background-color: #5864FF;
    color: #fff;
    font-weight: 700;
    border:none;
    transition: background-color 0.3s ease;
}

.cst-primary-btn:hover{
    background-color: #414eff;
    filter: drop-shadow(0px 0px 10px rgba(88, 100, 255, 0.50));
}

.cst-ghost-btn{
    background-color: #EFF0FF;
    color: #5864FF;
    border-radius: 4px;
    font-weight: 700;
}

.cst-ghost-btn:hover{
    background-color: #dcdfff;
    color: #5864FF;
}

.cst-btn-big{
    padding: 14px 20px;
    border-radius: 10px;
}

.blue-shadow{
    filter: drop-shadow(0px 0px 10px rgba(88, 100, 255, 0.40));
}

  
/* 버튼 - 모바일 햄버거버튼 */
.navbar-toggler {
    border-color: #414eff; /* 버튼 테두리 색상 변경 */
  }
  
  /* 햄버거 버튼 선 스타일 */
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23414eff' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}



/* 모달 */


@media (min-width: 768px) {
    .modal-custom .modal-dialog {
        max-width: 80%;
    }
}

.modal-body{
    width: 80;
}


.modal-body-scroll {
    max-height: 80vh;
    overflow-y: auto;
  }

  .modal-body-scroll::-webkit-scrollbar {
    width: 10px;
  }
  
  .modal-body-scroll::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .modal-body-scroll::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  .modal-body-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
.modal-body-img{
    padding-bottom: 10px;
}

.modal-dialog {
    max-width: 800px;
  }

.modal-body img {
    max-width: 100%;
    height: auto;
  }